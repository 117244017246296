import React from "react";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { Text, ThemeProps } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

const FollowUsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
    color: ${theme.colors.NEUTRAL["00"]};
  `
);

const SocialIcon = styled.a<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["00"]};
    opacity: 0.5; 
    transition: opacity 0.3s ease-in-out; 

    &:hover { 
      opacity: 1;
    }
  `
);

const FollowUs = styled(Text)`
  margin-top: 3px;
`;

const SocialsContainer = styled.div`
  display: flex;
`;

export const Socials = () => {
  return (
    <>
      <FollowUsContainer>
        <FollowUs>
          <FormattedMessage
            id="footer.socials.follow"
            defaultMessage="Follow us on"
          />
        </FollowUs>
      </FollowUsContainer>
      <SocialsContainer>
        <SocialIcon href="https://www.linkedin.com/company/18258498/">
          <FontAwesomeIcon size="lg" icon={faLinkedin} />
        </SocialIcon>
        <SocialIcon href="https://www.facebook.com/deliverrinc">
          <FontAwesomeIcon size="lg" icon={faFacebookSquare} />
        </SocialIcon>
        <SocialIcon href="https://twitter.com/deliverrinc">
          <FontAwesomeIcon size="lg" icon={faTwitter} />
        </SocialIcon>
      </SocialsContainer>
    </>
  );
};
