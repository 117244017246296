import React, { FC } from "react";
import styled from "@emotion/styled";
import { Divider, ThemeProps, useAboveMedium } from "@deliverr/ui";
import { TrackingAddress } from "./TrackingAddress";
import { OrderInformationList } from "./OrderInformationList";
import {
  OrderInformationProps,
  TrackingDetailsProps,
} from "./interface/OrderDetailsInterface";

interface OrderDetailsProps {
  orderInformation: OrderInformationProps[];
  fromTrackingDetails: TrackingDetailsProps;
  toTrackingDetails: TrackingDetailsProps;
}

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  color: ${theme.colors.NEUTRAL["500"]};
  
  ${theme.mediaQueries.MD}{
    flex-direction: row;
  }
`
);

const StyledDivider = styled(Divider)<ThemeProps>(
  ({ theme }) => `
  border-color: ${theme.colors.NEUTRAL["60"]};
`
);

export const OrderDetails: FC<OrderDetailsProps> = ({
  orderInformation,
  fromTrackingDetails,
  toTrackingDetails,
}) => {
  const isAboveMedium = useAboveMedium();

  return (
    <Container>
      <OrderInformationList orderInformation={orderInformation} />
      <StyledDivider hidden={isAboveMedium} />
      <TrackingAddress
        title={fromTrackingDetails.title}
        address={fromTrackingDetails.address}
      />
      <TrackingAddress
        title={toTrackingDetails.title}
        address={toTrackingDetails.address}
      />
      <StyledDivider hidden={isAboveMedium} />
    </Container>
  );
};
