import React, { FC } from "react";
import styled from "@emotion/styled";
import { ThemeProps, Text } from "@deliverr/ui";
import { TrackingLocation } from "./TrackingLocation";
import { TrackingDetailsProps } from "./interface/OrderDetailsInterface";
import { orderShipmentTitleRecord } from "../../constants/OrderDetailConstants";
import { FormattedMessage } from "react-intl";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.S4};

  ${theme.mediaQueries.MD}{
    margin-top: 0;
  }
`
);

const ContentContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
  margin-bottom: ${theme.spacing.S4};
`
);

export const TrackingAddress: FC<TrackingDetailsProps> = ({
  title,
  address,
}) => (
  <Container>
    <ContentContainer>
      <Text bold={true}>
        <FormattedMessage {...orderShipmentTitleRecord[title]} />
      </Text>
    </ContentContainer>
    <ContentContainer>
      <TrackingLocation address={address} />
    </ContentContainer>
  </Container>
);
