import { Anchor, Box, Notification, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { useLoaderData } from "react-router-dom";
import { maxWidthCenterNarrowStyle } from "../common/styleUtils";
import { TrackingEventsTable } from "../components/trackingEventsTable/TrackingEventsTable";
import ProgressBar from "../components/progressBar/ProgressBar";
import { FreightBolTracking } from "../models/FreightBolTracking";
import { OrderCard } from "../components/Order/OrderCard";
import { FormattedMessage } from "react-intl";
import { MailToFreight } from "../common/linkToWebsite";

const Container = styled(Box)<ThemeProps>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL["00"]};
    padding: ${theme.spacing.S7} 0;

    > * {
      ${maxWidthCenterNarrowStyle}
      padding: ${theme.spacing.S5};
    }

    > .inner { 
      padding: ${theme.spacing.S5};
      display: flex; 
      flex-direction: column; 
      gap: ${theme.spacing.S9};
    }
  `
);

export const TrackingDetails: FC = () => {
  const trackingDetails = useLoaderData() as FreightBolTracking;

  return (
    <Container>
      <div className="inner">
        <OrderCard />
        <ProgressBar />
        <TrackingEventsTable trackingEvents={trackingDetails.events} />
        <Notification
          appearance="PRIMARY"
          content={{
            title: (
              <FormattedMessage
                id="trackingDetails.troubleShoot.title"
                defaultMessage="Troubleshooting your shipment"
              />
            ),
            description: (
              <FormattedMessage
                id="trackingDetails.troubleShoot.description"
                defaultMessage="If you want to cancel or reschedule this shipment, reach out to {email} at least 24 hours before the start of your pickup day. For any other issues reach out to your account representative."
                values={{
                  email: (
                    <Anchor href={MailToFreight}>freight@deliverr.com</Anchor>
                  ),
                }}
              />
            ),
          }}
        />
      </div>
    </Container>
  );
};
