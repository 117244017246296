import { MessageDescriptor, defineMessages } from "react-intl";
import {
  FreightBolStatus,
  FreightShipmentMode,
} from "../models/FreightBolTracking";
import {
  OrderDetailsHeadings,
  OrderShipmentTitle,
} from "../components/Order/interface/OrderDetailsInterface";

export const orderDetailsHeadingRecord: Record<
  OrderDetailsHeadings,
  MessageDescriptor
> = defineMessages({
  [OrderDetailsHeadings.NUMBER_OF_PALLETS]: {
    id: "orderDetails.numberOfPallets",
    defaultMessage: "Number of pallets",
  },
  [OrderDetailsHeadings.MODE_OF_TRANSPORTATION]: {
    id: "orderDetails.modeOfTransportation",
    defaultMessage: "Mode of transportation",
  },
  [OrderDetailsHeadings.CARRIER_NAME]: {
    id: "orderDetails.carrierName",
    defaultMessage: "Carrier name",
  },
});

export const orderShipmentTitleRecord: Record<
  OrderShipmentTitle,
  MessageDescriptor
> = defineMessages({
  [OrderShipmentTitle.FROM]: {
    id: "orderDetails.from",
    defaultMessage: "Ship from",
  },
  [OrderShipmentTitle.TO]: {
    id: "orderDetails.to",
    defaultMessage: "Ship to",
  },
});

export const orderStatusRecord: Record<FreightBolStatus, MessageDescriptor> =
  defineMessages({
    [FreightBolStatus.CREATED]: {
      id: "orderDetails.status.created",
      defaultMessage: "Pending carrier",
    },
    [FreightBolStatus.TENDERED]: {
      id: "orderDetails.status.tendered",
      defaultMessage: "Tendered",
    },
    [FreightBolStatus.IN_TRANSIT]: {
      id: "orderDetails.status.inTransit",
      defaultMessage: "In transit",
    },
    [FreightBolStatus.DELIVERED]: {
      id: "orderDetails.status.delivered",
      defaultMessage: "Delivered",
    },
    [FreightBolStatus.CANCELLED]: {
      id: "orderDetails.status.cancelled",
      defaultMessage: "Cancelled",
    },
  });

export const orderModeOfTransportMessages: Record<
  FreightShipmentMode,
  MessageDescriptor
> = defineMessages({
  [FreightShipmentMode.DRAYAGE]: {
    id: "orderDetails.modeOfTransportation.drayage",
    defaultMessage: "Drayage",
  },
  [FreightShipmentMode.FTL]: {
    id: "orderDetails.modeOfTransportation.ftl",
    defaultMessage: "Full truckload",
  },
  [FreightShipmentMode.INTERMODAL]: {
    id: "orderDetails.modeOfTransportation.intermodal",
    defaultMessage: "Intermodal",
  },
  [FreightShipmentMode.LTL]: {
    id: "orderDetails.modeOfTransportation.ltl",
    defaultMessage: "Less than truckload",
  },
  [FreightShipmentMode.TRANSLOAD]: {
    id: "orderDetails.modeOfTransportation.transload",
    defaultMessage: "Transload",
  },
  [FreightShipmentMode.VLTL]: {
    id: "orderDetails.modeOfTransportation.vltl",
    defaultMessage: "Volume less than truckload",
  },
});
