import { DeliverrAddress } from "@deliverr/commons-objects";
import { FreightBolStatus } from "../../../models/FreightBolTracking";

export interface OrderInformationProps {
  title: OrderDetailsHeadings;
  value: JSX.Element | string;
}

export interface TrackingDetailsProps {
  title: OrderShipmentTitle;
  address: DeliverrAddress;
}

export interface OrderStatusProps {
  bolId: string;
  status: FreightBolStatus;
  lastUpdatedAt: Date;
}

export enum OrderDetailsHeadings {
  NUMBER_OF_PALLETS = "NUMBER_OF_PALLETS",
  MODE_OF_TRANSPORTATION = "MODE_OF_TRANSPORTATION",
  CARRIER_NAME = "CARRIER_NAME",
}

export enum OrderShipmentTitle {
  FROM = "FROM",
  TO = "TO",
}
