import { Table, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { TableHeadings } from "../../constants/trackingEventsConstants";
import { FreightTrackingEvent } from "../../models/FreightBolTracking";
import {
  formatTrackingTableEvent,
  sortTrackingTableEvents,
} from "../../utils/trackingEventsUtils";
import React, { FC } from "react";

interface TrackingEventsProps {
  trackingEvents: FreightTrackingEvent[];
}

const TableHeadingLabels = TableHeadings.map((heading) => (
  <FormattedMessage key={heading.id} {...heading} />
));

const StyledTable = styled(Table)<ThemeProps>(
  ({ theme }) => `
  box-shadow: 0px 0px 2px rgba(218, 225, 232, 0.5), 0px 2px 4px rgba(218, 225, 232, 0.5);
  border-radius: ${theme.border.radius.R2};
  padding: ${theme.spacing.S5};

  tr {
    border: none;
  }

  th {
    color: ${theme.colors.NEUTRAL[500]};
    padding-bottom: ${theme.spacing.S6};
    font-family: ${theme.font.family.STANDARD};

    ${theme.mediaQueries.MD}{
      padding-bottom: ${theme.spacing.S4};
    } 
  }

  td {
    padding: ${theme.spacing.S4} ${theme.spacing.S5};
    color: ${theme.colors.NEUTRAL[300]};

    &:last-child {
      padding-bottom: ${theme.spacing.S5};
    }
  }
  
  @media (max-width: ${theme.breakpoints.SM}) {
    tr {
      display: flex;
      flex-direction: column;
    }

    th:not(:first-child) {
      display: none;
    }

    td {
      padding-left: ${theme.spacing.S6};
      padding-top: 0;
    }

    td:not(:last-child) {
      padding-bottom: ${theme.spacing.S2};
    }

    td:first-child {
      font-weight: ${theme.font.weight.BOLD};
    }
  }
`
);

export const TrackingEventsTable: FC<TrackingEventsProps> = ({
  trackingEvents,
}) => {
  const tableData = sortTrackingTableEvents(trackingEvents).map((event) =>
    formatTrackingTableEvent(event)
  );

  return (
    <StyledTable headings={TableHeadingLabels} rows={tableData} caption="" />
  );
};
