import { DefaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, ReactNode, useState } from "react";
import { NavToggle } from "./NavToggle";

const NavAnchor = styled.div<ThemeProps>(
  ({ theme }) => `
    transition: color 0.3s ease-in-out;
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL["00"]};
    display: flex; 
    align-items: center;

    &:hover { 
      color: ${theme.colors.BLUE["300"]};
      cursor: pointer; 
    }
  `
);

const SubNav = styled.div<{ expanded: boolean }, DefaultTheme>(
  ({ theme, expanded }) => `
    display: ${expanded ? "initial" : "none"};

    ${theme.mediaQueries.LG} {
      position: absolute;
      z-index: ${theme.zindex.Z1};
      left: 50%;

      transform: translateX(-50%)
    }
  `
);

interface NavItemProps {
  children: ReactNode;
  href?: string;
  subNav?: ReactNode;
  expandsOn?: "click" | "hover";
}

export const NavItem: FC<NavItemProps> = ({
  children,
  href,
  subNav,
  expandsOn = "hover",
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onMouseOver = () => {
    if (expandsOn === "hover") {
      setIsExpanded(true);
    }
  };

  const onMouseOut = () => {
    if (expandsOn === "hover") {
      setIsExpanded(false);
    }
  };

  const onClick = () => {
    if (expandsOn === "click") {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      style={{ position: "relative" }}
    >
      <NavAnchor>
        <a href={href}>{children}</a>
        {subNav && <NavToggle expanded={isExpanded} />}
      </NavAnchor>
      <SubNav expanded={isExpanded}>{subNav}</SubNav>
    </div>
  );
};
