import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, PropsWithChildren } from "react";
import { bgColor } from "../../common/styleUtils";

const Outer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex; 
    justify-content: center;
    background: ${bgColor};
    -webkit-font-smoothing: antialiased;
  `
);

const Inner = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%; 
    overflow-x: hidden;
    
    > * {
      padding: ${theme.spacing.S4};
    }
`
);

export const Container: FC<PropsWithChildren> = ({ children }) => (
  <Outer>
    <Inner>{children}</Inner>
  </Outer>
);
