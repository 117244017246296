import React from "react";
import { FreightBolTracking } from "../../../models/FreightBolTracking";
import {
  OrderDetailsHeadings,
  OrderInformationProps,
} from "../interface/OrderDetailsInterface";
import { orderModeOfTransportMessages } from "../../../constants/OrderDetailConstants";
import { FormattedMessage } from "react-intl";

export const mapOrderInformation = (
  freightBolTracking: FreightBolTracking
): OrderInformationProps[] => [
  {
    title: OrderDetailsHeadings.NUMBER_OF_PALLETS,
    value: (freightBolTracking.totalPallets ?? 0).toString(),
  },
  {
    title: OrderDetailsHeadings.MODE_OF_TRANSPORTATION,
    value: (
      <FormattedMessage
        {...orderModeOfTransportMessages[freightBolTracking.mode!]}
      />
    ),
  },
  {
    title: OrderDetailsHeadings.CARRIER_NAME,
    value: freightBolTracking.carrierName ?? (
      <FormattedMessage
        id="orderDetails.carrierName"
        defaultMessage="Pending carrier"
      />
    ),
  },
];
