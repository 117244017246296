import React from "react";
import { Container } from "../components/nav/NavContainer";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/footer/Footer";
import { SignUp } from "../components/footer/FooterSignup";
import { Nav } from "../components/nav/Nav";

export const Root = () => (
  <Container>
    <Nav />
    <Outlet />
    <SignUp />
    <Footer />
  </Container>
);
