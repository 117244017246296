import React, { useState } from "react";
import { IconSizes, IconV2, ThemeProps, useAboveLarge } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Logo } from "../../assets/Logo";
import { FormattedMessage } from "react-intl";
import { NavItem } from "./NavItem";
import { NavDropdown } from "./NavDropdown";
import { solutions } from "./solutions";
import {
  DeliverrWebsite,
  linkToWebsite,
  ResourceWebsite,
} from "../../common/linkToWebsite";
import { NavBlade } from "./NavBlade";
import { maxWidthCenterStyle } from "../../common/styleUtils";

const Navbar = styled.nav<ThemeProps>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    position: relative; 
    ${maxWidthCenterStyle}
    z-index: ${theme.zindex.Z1};
    padding: ${theme.spacing.S7} ${theme.spacing.S4};

    a {
      text-decoration: none;
    }
  `
);

const NavGroup = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex; 
    gap: ${theme.spacing.S8};
    align-items: center;
    font-size: ${theme.font.size.F4};
  `
);

const NavLogo = styled.a<ThemeProps>(
  ({ theme }) => `
    fill: ${theme.colors.NEUTRAL["00"]};
    transition: fill 0.3s ease-in-out;

    &:hover {
      fill: ${theme.colors.BLUE["300"]};
      cursor: pointer;
    }
  `
);

const GetStartedLink = styled.div<ThemeProps>(
  ({ theme }) => `
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    border-radius: ${theme.border.radius.R3};
    transition: border-color 0.3s ease-in-out;
    padding: ${theme.spacing.S4};

    &:hover { 
      border-color: ${theme.colors.BLUE["300"]}
    }
  `
);

const NavButton = styled.button<ThemeProps>(
  ({ theme }) => `
    right: ${theme.spacing.S5};
    position: absolute;
    z-index: ${theme.zindex.Z2};
    color: ${theme.colors.NEUTRAL["00"]};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }
  `
);

export const Nav = () => {
  const isMinLarge = useAboveLarge();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Navbar>
      <NavLogo href={linkToWebsite(DeliverrWebsite, "/freight")}>
        <Logo />
      </NavLogo>
      <NavBlade isExpanded={isExpanded}>
        <NavItem href="/">
          <FormattedMessage
            defaultMessage="Track your shipment"
            id="nav.track"
          />
        </NavItem>
        <NavItem subNav={<NavDropdown items={solutions} />} expandsOn="click">
          <FormattedMessage
            defaultMessage="Discover Deliverr"
            id="nav.discover"
          />
        </NavItem>
        <NavItem href={linkToWebsite(ResourceWebsite, "/freight")}>
          <GetStartedLink>
            <FormattedMessage defaultMessage="Get Started" id="nav.start" />
          </GetStartedLink>
        </NavItem>
      </NavBlade>
      {isMinLarge ? (
        <NavGroup>
          <NavItem href="/">
            <FormattedMessage
              defaultMessage="Track your shipment"
              id="nav.track"
            />
          </NavItem>
          <NavItem subNav={<NavDropdown items={solutions} />}>
            <FormattedMessage
              defaultMessage="Discover Deliverr"
              id="nav.discover"
            />
          </NavItem>
          <NavItem href={linkToWebsite(ResourceWebsite, "/freight")}>
            <GetStartedLink>
              <FormattedMessage defaultMessage="Get Started" id="nav.start" />
            </GetStartedLink>
          </NavItem>
        </NavGroup>
      ) : (
        <NavButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? (
            <IconV2 type="close" size={IconSizes["2x"]} />
          ) : (
            <IconV2 type="bars" size={IconSizes["2x"]} />
          )}
        </NavButton>
      )}
    </Navbar>
  );
};
