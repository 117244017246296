import React from "react";
import { FormattedMessage } from "react-intl";
import {
  DeliverrWebsite,
  linkToWebsite,
  SupportWebsite,
} from "../../common/linkToWebsite";
import { FooterLinkProps } from "./FooterLink";

export const company: FooterLinkProps[] = [
  {
    href: linkToWebsite(SupportWebsite, "/hc/en-us"),
    label: (
      <FormattedMessage id="footer.company.support" defaultMessage="Support" />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/about-us"),
    label: (
      <FormattedMessage id="footer.company.about" defaultMessage="About Us" />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/careers"),
    label: (
      <FormattedMessage id="footer.company.careers" defaultMessage="Careers" />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/blog"),
    label: <FormattedMessage id="footer.company.blog" defaultMessage="Blog" />,
  },
  {
    href: linkToWebsite("", "mailto:info@deliverr.com"),
    label: (
      <FormattedMessage id="footer.company.contact" defaultMessage="Contact" />
    ),
  },
];
