import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";

const StyledLink = styled.a<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["00"]};
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD};
    text-decoration: none;
    line-height: 125%;

    &:hover {
      text-decoration: underline;
    }
  `
);

export interface FooterLinkProps {
  href: string;
  label: ReactNode;
}

export const FooterLink: FC<FooterLinkProps> = ({ href, label }) => {
  return <StyledLink href={href}>{label}</StyledLink>;
};
