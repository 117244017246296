import { DefaultTheme, IconV2, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FreightBolStatus } from "../../models/FreightBolTracking";
import { progressBarColorMapper } from "./mapper/ProgressBarColorMapper";

export interface BarProps {
  progress: number;
  status: FreightBolStatus;
}

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `
);

const EventEllipse = styled.div<
  { isFilled: boolean; isCancelled: boolean },
  DefaultTheme
>(
  ({ theme, isFilled, isCancelled }) => `
    width: ${theme.spacing.S6};
    height: ${theme.spacing.S6};
    background-color: ${progressBarColorMapper(theme, isFilled, isCancelled)};
    border-radius: 50%;
    z-index: ${theme.zindex.Z1};
    flex-shrink: 0;
  `
);

const LineContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    height: ${theme.spacing.S2};
    background-color: ${theme.colors.NEUTRAL[100]};
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 -1px; /* to hide the gap between the bar and ellipse */
  `
);

const Line = styled.div<
  { progress: number; isCancelled: boolean },
  DefaultTheme
>(
  ({ theme, progress, isCancelled }) => `
    height: 100%;
    background-color:${
      isCancelled ? theme.colors.RED[300] : theme.colors.GREEN[300]
    };
    width: ${progress}%;
  `
);

const LineEllipse = styled.div<
  { progress: number; isHidden: boolean },
  DefaultTheme
>(
  ({ theme, progress, isHidden }) => `
    display: ${isHidden ? "none" : "block"};
    width: ${theme.spacing.S4};
    height: ${theme.spacing.S4};
    background-color: ${theme.colors.GREEN[300]};
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: ${progress}%;
  `
);

const IconContainer = styled.div<
  { progress: number; isHidden: boolean },
  DefaultTheme
>(
  ({ theme, isHidden, progress }) => `
    display: ${isHidden ? "none" : "block"};
    position: absolute;
    bottom: 100%;
    margin-bottom: ${theme.spacing.S3};
    left: calc(${progress}% - ${theme.spacing.S1});
  
    > svg {
      color: ${theme.colors.NEUTRAL[500]};
    }
  `
);

export const Bar: FC<BarProps> = ({ progress, status }) => {
  const isCancelled = status === FreightBolStatus.CANCELLED;
  return (
    <Container>
      <EventEllipse isFilled={progress > 0} isCancelled={isCancelled} />
      <LineContainer>
        <Line progress={progress} isCancelled={isCancelled} />
        <LineEllipse
          progress={progress}
          isHidden={progress === 0 || progress === 100 || isCancelled}
        />
        <IconContainer
          progress={progress}
          isHidden={progress === 0 || progress === 100 || isCancelled}
        >
          <IconV2 type="truck" size="2x" aria-label="in transit" />
        </IconContainer>
      </LineContainer>
      <EventEllipse isFilled={progress === 100} isCancelled={isCancelled} />
    </Container>
  );
};
