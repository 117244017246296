import React, { ReactNode, FC } from "react";
import { Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

const NavDropdownItem = styled.a<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S3} ${theme.spacing.S2};
    border-radius: ${theme.border.radius.R3};
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;

    p {
      margin-top: 0;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: ${theme.colors.BLUE["300"]};
      }
    }

    &:hover { 
      background-color: ${theme.colors.NEUTRAL["40"]};
    }
  `
);

export interface NavDropdownItemProps {
  children: ReactNode;
  href: string;
}

export const NavDropdownItemLink: FC<NavDropdownItemProps> = ({
  children,
  href,
}) => (
  <NavDropdownItem href={href}>
    <Text bold={true} appearance="INFO">
      {children}
    </Text>
  </NavDropdownItem>
);
