import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { trackingDataLoader } from "./loaders/trackingDataLoader";
import { BolInput } from "./pages/BolInput";
import { Root } from "./pages/Root";
import { TrackingDetails } from "./pages/TrackingDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Navigate to="/" state={{ error: false }} />,
    children: [
      {
        path: "/bol/:bolId",
        loader: trackingDataLoader,
        errorElement: <Navigate to="/" state={{ error: true }} />,
        element: <TrackingDetails />,
      },
      {
        path: "/",
        element: <BolInput />,
      },
    ],
  },
]);

export default () => <RouterProvider router={router} />;
