import React from "react";
import { FormattedMessage } from "react-intl";
import { DeliverrWebsite, linkToWebsite } from "../../common/linkToWebsite";
import { NavDropdownItemProps } from "./NavDropdownItemLink";

export const solutions: NavDropdownItemProps[] = [
  {
    href: linkToWebsite(DeliverrWebsite, "/"),
    children: (
      <FormattedMessage
        defaultMessage="Fulfillment"
        id="nav.discover.fulfillment"
      />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/freight"),
    children: (
      <FormattedMessage defaultMessage="Freight" id="nav.discover.freight" />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/reserve-storage"),
    children: (
      <FormattedMessage
        defaultMessage="Reserve Storage"
        id="nav.discover.storage"
      />
    ),
  },
  {
    href: linkToWebsite(DeliverrWebsite, "/prep"),
    children: <FormattedMessage defaultMessage="Prep" id="nav.discover.prep" />,
  },
];
