import React from "react";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { EventTypeLabelMap } from "../constants/trackingEventsConstants";
import {
  FreightTrackingEvent,
  FreightTrackingLocation,
} from "../models/FreightBolTracking";
import { getLocalTimezone } from "./dateUtils";

export const sortTrackingTableEvents = (
  events: FreightTrackingEvent[]
): FreightTrackingEvent[] =>
  events
    .slice()
    .sort(
      (eventA, eventB) =>
        new Date(eventB.occurredAt).valueOf() -
        new Date(eventA.occurredAt).valueOf()
    );

export const formatTrackingTableEvent = (
  event: FreightTrackingEvent
): [JSX.Element, string, string] => [
  EventTypeLabelMap[event.eventType] ? (
    <FormattedMessage
      key={EventTypeLabelMap[event.eventType].id}
      {...EventTypeLabelMap[event.eventType]}
    />
  ) : (
    <FormattedMessage
      id="trackingEventsTable.eventTypeLabel.unknownEvent"
      defaultMessage="Shipment event"
    />
  ),
  formatTrackingTableLocation(event.location),
  formatTrackingTableEventDate(event.occurredAt),
];

export const formatTrackingTableLocation = (
  location: FreightTrackingLocation | undefined
): string =>
  location?.city && location?.state
    ? `${location?.city}, ${location?.state}`
    : "-";

/**
 * Need to capture local time zone abbreviation as well as get local time
 * Default format is "Dec 31, 2022 - 11:59 am EST"
 * If date does not have hours and minutes, format is "Dec 31, 2022"
 */
export const formatTrackingTableEventDate = (date: string): string => {
  const timeZone = getLocalTimezone(date);
  const dateFormat = date.length > 10 ? "MMM d, yyyy - h:mm aa" : "MMM d, yyyy";
  return `${format(new Date(date), dateFormat)}${
    date.length > 10 ? ` ${timeZone}` : ""
  }`;
};
