import React, { FC } from "react";
import styled from "@emotion/styled";
import { OrderDetails } from "./OrderDetails";
import { OrderStatus } from "./OrderStatus";
import { useLoaderData } from "react-router-dom";
import { FreightBolTracking } from "../../models/FreightBolTracking";
import { mapOrderInformation } from "./mappers/OrderDetailsMapper";
import {
  OrderShipmentTitle,
  TrackingDetailsProps,
} from "./interface/OrderDetailsInterface";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const OrderCard: FC = () => {
  const freightBolTrackingData = useLoaderData() as FreightBolTracking;
  const { bolId, bolStatus, updatedAt, pickupDetails, deliveryDetails } =
    freightBolTrackingData;

  const orderInformation = mapOrderInformation(freightBolTrackingData);
  const fromTrackingDetails: TrackingDetailsProps = {
    title: OrderShipmentTitle.FROM,
    address: pickupDetails.address,
  };
  const toTrackingDetails: TrackingDetailsProps = {
    title: OrderShipmentTitle.TO,
    address: deliveryDetails.address,
  };

  return (
    <Container>
      <OrderStatus bolId={bolId} status={bolStatus} lastUpdatedAt={updatedAt} />
      <OrderDetails
        orderInformation={orderInformation}
        fromTrackingDetails={fromTrackingDetails!}
        toTrackingDetails={toTrackingDetails!}
      />
    </Container>
  );
};
