import React, { FC } from "react";
import { IconV2, ThemeProps, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { TrackingDetailsProps } from "./interface/OrderDetailsInterface";

type TrackingLocationProps = Pick<TrackingDetailsProps, "address">;

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: ${theme.spacing.S1};
`
);

const LocationContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S2};
  margin-top: ${theme.spacing.S1};
`
);

export const TrackingLocation: FC<TrackingLocationProps> = ({ address }) => (
  <Container>
    <IconV2 type="location" />
    <LocationContainer>
      <Text>{address.name}</Text>
      <Text>{address.street1}</Text>
      {address.street2 && <Text>{address.street2}</Text>}
      <Text>
        {address.city}, {address.state} {address.zip}
      </Text>
      <Text>{address.country}</Text>
    </LocationContainer>
  </Container>
);
