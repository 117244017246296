import React from "react";
import { DesktopBolInput } from "../components/bolInput/DesktopBolInput";
import { MobileBolInput } from "../components/bolInput/MobileBolInput";
import { useBolInput } from "../components/bolInput/useBolInput";

export const BolInput = () => {
  const { isAboveLarge, ...bolInputProps } = useBolInput();

  return (
    <>
      {isAboveLarge ? (
        <DesktopBolInput {...bolInputProps} />
      ) : (
        <MobileBolInput {...bolInputProps} />
      )}
    </>
  );
};
