import { Text, ThemeProps, Title } from "@deliverr/ui";
import { DefaultTheme, TextAlign } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  DeliverrWebsite,
  linkToWebsite,
  ResourceWebsite,
} from "../../common/linkToWebsite";
import {
  bgColor,
  buttonHeight,
  flexCenter,
  purpleButtonStyle,
} from "../../common/styleUtils";

const SignUpContainer = styled.div<{ isHomePage: boolean }, DefaultTheme>(
  ({ theme, isHomePage }) => `
    padding-top: ${isHomePage ? "130px" : "90px"}; 
    padding-bottom: 90px;
    ${flexCenter}
    flex-direction: column; 
    gap: ${theme.spacing.S4};
    color: ${
      isHomePage ? theme.colors.NEUTRAL["500"] : theme.colors.NEUTRAL["00"]
    };
    background-color: ${isHomePage ? theme.colors.NEUTRAL["00"] : bgColor};

    ${theme.mediaQueries.LG}{
      padding-top: 90px; 
      color: ${
        isHomePage ? theme.colors.NEUTRAL["500"] : theme.colors.NEUTRAL["00"]
      };
      background-color: ${isHomePage ? theme.colors.NEUTRAL["00"] : bgColor};
    }
  `
);

const SubText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F5};
    font-weight: 300;
    line-height: ${theme.font.lineHeight.LH6};

    ${theme.mediaQueries.LG}{
      font-size: ${theme.font.size.F3};
    }
`
);

const GetQuoteLinkBtn = styled.a<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    border-radius: ${theme.border.radius.R5};
    color: ${theme.colors.NEUTRAL["00"]};
    font-weight: ${theme.font.weight.BOLD};
    ${flexCenter}
    ${purpleButtonStyle}
    ${buttonHeight}
    text-decoration: none;

    ${theme.mediaQueries.LG}{
      width: 200px; 
    }
  `
);

export const SignUp = () => {
  const location = useLocation();

  return (
    <SignUpContainer isHomePage={location.pathname === "/"}>
      <Title textAlign={TextAlign.center}>
        <FormattedMessage
          id="footer.title"
          defaultMessage="Freight made simpler"
        />
      </Title>
      <SubText textAlign={TextAlign.center} size="bodyLarge">
        <FormattedMessage
          id="footer.subTitle"
          defaultMessage="Try our clear, upfront pricing and guaranteed transit times"
        />
      </SubText>
      <GetQuoteLinkBtn href={linkToWebsite(ResourceWebsite, "/freight")}>
        <FormattedMessage id="footer.getQuote" defaultMessage="Get a quote" />
      </GetQuoteLinkBtn>
      <Text>
        <FormattedMessage
          id="footer.learnMore"
          defaultMessage="Learn more about {learnMoreLink}"
          values={{
            learnMoreLink: (
              <a href={linkToWebsite(DeliverrWebsite, "/freight")}>
                Deliverr Freight
              </a>
            ),
          }}
        />
      </Text>
    </SignUpContainer>
  );
};
