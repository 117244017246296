import { Button, Input, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { purpleButtonStyle, buttonHeight } from "../../common/styleUtils";

export const BolInputButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    border-radius: ${theme.border.radius.R5};
    border: none;
    ${purpleButtonStyle}
    ${buttonHeight}
  `
);

export const BolInputComponent = styled(Input)<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S5};
    border-radius: ${theme.border.radius.R5};
    ${buttonHeight}
  `
);

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;
