import { LoaderFunctionArgs } from "react-router-dom";
import { FreightBolTracking } from "../models/FreightBolTracking";

export const trackingDataLoader = async ({
  params,
}: LoaderFunctionArgs): Promise<FreightBolTracking> => {
  const trackingResults = await fetch(
    `${process.env.REACT_APP_FREIGHT_SERVICE_URL}/v1/public/tracking/order-load/bol/${params.bolId}/events`
  );

  if (!trackingResults.ok) {
    throw new Error("BOL not found");
  }

  const trackingDetails = (await trackingResults.json()) as FreightBolTracking;

  return trackingDetails;
};
