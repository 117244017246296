import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, PropsWithChildren } from "react";

const NAV_BLADE_WIDTH = 250;

export const NAV_BLADE_COLOR = "#000131";

const Container = styled.div<NavBladeProps, DefaultTheme>(
  ({ theme, isExpanded }) => `
    width: ${NAV_BLADE_WIDTH}px;
    margin-top: -${theme.spacing.S3};
    background: ${NAV_BLADE_COLOR};
    padding: ${theme.spacing.S9} ${theme.spacing.S5};
    display: ${isExpanded ? "flex" : "none"}; 
    gap: ${theme.spacing.S4};
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.zindex.Z1};
    color: ${theme.colors.NEUTRAL["00"]};
  `
);

type NavBladeProps = {
  isExpanded: boolean;
} & PropsWithChildren;

export const NavBlade: FC<NavBladeProps> = ({ children, isExpanded }) => {
  return <Container isExpanded={isExpanded}>{children}</Container>;
};
