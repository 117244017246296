import { defineMessage, MessageDescriptor } from "react-intl";
import { FreightTrackingEventType } from "../models/FreightBolTracking";

export const EventTypeLabelMap: Record<
  FreightTrackingEventType,
  MessageDescriptor
> = {
  [FreightTrackingEventType.CREATED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.created",
    defaultMessage: "Shipment created",
  }),
  [FreightTrackingEventType.TENDERED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.tendered",
    defaultMessage: "Shipment tendered",
  }),
  [FreightTrackingEventType.PICKED_UP]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.inTransit",
    defaultMessage: "Shipment picked up",
  }),
  [FreightTrackingEventType.DELIVERED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.delivered",
    defaultMessage: "Shipment delivered",
  }),
  [FreightTrackingEventType.CANCELLED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.cancelled",
    defaultMessage: "Shipment cancelled",
  }),
  [FreightTrackingEventType.ESTIMATED_DELIVERY_DATE_CHANGED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.estimatedDeliveryDateChanged",
    defaultMessage: "Shipment delivery date changed",
  }),
  [FreightTrackingEventType.ESTIMATED_PICKUP_DATE_CHANGED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.estimatedPickupDateChanged",
    defaultMessage: "Shipment pickup date changed",
  }),
  [FreightTrackingEventType.LOCATION_UPDATED]: defineMessage({
    id: "trackingEventsTable.eventTypeLabel.locationUpdated",
    defaultMessage: "Shipment location updated",
  }),
};

/* Array of individually defined messages to preserve table headings order */
export const TableHeadings = [
  defineMessage({
    id: "trackingEventsTable.event",
    defaultMessage: "Event",
  }),
  defineMessage({
    id: "trackingEventsTable.location",
    defaultMessage: "Location",
  }),
  defineMessage({
    id: "trackingEventsTable.date",
    defaultMessage: "Date",
  }),
];
