import { MessageDescriptor, defineMessages } from "react-intl";
import { FreightBolStatus } from "../../models/FreightBolTracking";

export const ProgressBarPickupLabelMap: Record<
  FreightBolStatus,
  MessageDescriptor
> = defineMessages({
  [FreightBolStatus.CREATED]: {
    id: "progressBar.status.pickup.created",
    defaultMessage: "Pickup window",
  },
  [FreightBolStatus.TENDERED]: {
    id: "progressBar.status.pickup.tendered",
    defaultMessage: "Pickup estimation",
  },
  [FreightBolStatus.IN_TRANSIT]: {
    id: "progressBar.status.pickup.inTransit",
    defaultMessage: "Picked up",
  },
  [FreightBolStatus.DELIVERED]: {
    id: "progressBar.status.pickup.delivered",
    defaultMessage: "Picked up",
  },
  [FreightBolStatus.CANCELLED]: {
    id: "progressBar.status.pickup.cancelled",
    defaultMessage: "Pickup window",
  },
});

export const ProgressBarDeliveryLabelMap: Record<
  FreightBolStatus,
  MessageDescriptor
> = defineMessages({
  [FreightBolStatus.CREATED]: {
    id: "progressBar.status.delivery.created",
    defaultMessage: "Delivery window",
  },
  [FreightBolStatus.TENDERED]: {
    id: "progressBar.status.delivery.tendered",
    defaultMessage: "Delivery estimation",
  },
  [FreightBolStatus.IN_TRANSIT]: {
    id: "progressBar.status.delivery.inTransit",
    defaultMessage: "Delivery estimation",
  },
  [FreightBolStatus.DELIVERED]: {
    id: "progressBar.status.delivery.delivered",
    defaultMessage: "Delivered",
  },
  [FreightBolStatus.CANCELLED]: {
    id: "progressBar.status.delivery.cancelled",
    defaultMessage: "Delivery window",
  },
});
