import { ThemeProps, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { getLocalTimezone } from "../../utils/dateUtils";
import { DeliveryInformation } from "./mapper/ProgressBarStatusMapper";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.S5};
    gap: ${theme.spacing.S1};

    ${theme.mediaQueries.MD}{
      margin-bottom: 0;
    }
  `
);

const DateTime = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
    color: ${theme.colors.NEUTRAL["500"]};
  `
);

const formattedTime = (startTime: number, endTime?: number): JSX.Element => {
  return endTime ? (
    <DateTime size="bodyLarge">
      <FormattedTime value={new Date(startTime)} /> -{" "}
      <FormattedTime value={new Date(endTime)} />{" "}
    </DateTime>
  ) : (
    <DateTime size="bodyLarge">
      <FormattedTime value={startTime} />{" "}
      {getLocalTimezone(new Date(startTime).toString())}
    </DateTime>
  );
};

export const ProgressBarEndpointDetails: FC<DeliveryInformation> = ({
  status,
  date,
  startTime,
  endTime,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Text size="bodyLarge" appearance="INFO">
        {formatMessage(status)}
      </Text>
      <DateTime size="bodyLarge">
        <FormattedDate
          value={date}
          year="numeric"
          month="short"
          day="numeric"
        />
      </DateTime>
      {formattedTime(startTime, endTime)}
    </Container>
  );
};
