import { Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { FooterLink, FooterLinkProps } from "./FooterLink";

const Column = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    gap: ${theme.spacing.S4};
    margin-bottom: ${theme.spacing.S4};
  `
);

const FooterTitle = styled(Text)<ThemeProps>(
  ({ theme }) => `
    font-family: ${theme.font.family.TITLE};
    font-size: ${theme.font.size.F1};
  `
);

interface FooterColumnProps {
  title: ReactNode;
  items: FooterLinkProps[];
}

export const FooterColumn: FC<FooterColumnProps> = ({ title, items }) => {
  return (
    <Column>
      <FooterTitle appearance="INFO" bold={true}>
        {title}
      </FooterTitle>
      {items.map((footerLinkProps, index) => (
        <FooterLink {...footerLinkProps} key={index} />
      ))}
    </Column>
  );
};
