import React from "react";
import { FormattedMessage } from "react-intl";
import { DeliverrWebsite, linkToWebsite } from "../../common/linkToWebsite";
import { FooterLinkProps } from "./FooterLink";

export const popularArticles: FooterLinkProps[] = [
  {
    href: linkToWebsite(
      DeliverrWebsite,
      "/blog/less-than-a-truckload-complexities/"
    ),
    label: (
      <FormattedMessage
        id="footer.popularArticles.ltl"
        defaultMessage="How to Overcome the Complexities of LTL Freight"
      />
    ),
  },
  {
    href: linkToWebsite(
      DeliverrWebsite,
      "/blog/deliverr-logistics-services-freight"
    ),
    label: (
      <FormattedMessage
        id="footer.popularArticles.freight"
        defaultMessage="Introducing Deliverr Freight: Fast, affordable, and hassle-free transportation for the modern merchant"
      />
    ),
  },
];
