export const flexCenter = `
  display: flex; 
  align-items: center; 
  justify-content: center; 
`;

export const purpleButtonStyle = `
  background: linear-gradient(84.22deg, #711AFF 3.3%, #A661FF 104.8%);
  
  &:hover {
    cursor: pointer; 
    background: linear-gradient(84.16deg, #6100FF -11.35%, #7E48C4 99.23%);
  }
`;

export const buttonHeight = `
  height: 60px; 
`;

export const maxWidthCenterStyle = `
  max-width: 1172px;
  margin: 0 auto;
`;

export const maxWidthCenterNarrowStyle = `
  ${maxWidthCenterStyle}
  max-width: 879px;
`;

export const bgColor = "#041627";
