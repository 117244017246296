import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DeliveryInformation } from "../components/progressBar/mapper/ProgressBarStatusMapper";
import { progressBarHookService } from "../components/progressBar/ProgressBarService";
import { FreightBolTracking } from "../models/FreightBolTracking";

export const useProgressBar = () => {
  const freightBolTrackingData = useLoaderData() as FreightBolTracking;
  const [progress, setProgress] = useState(0);
  const [pickupInformation, setPickupInformation] =
    useState<DeliveryInformation>();
  const [deliveryInformation, setDeliveryInformation] =
    useState<DeliveryInformation>();
  useEffect(() => {
    progressBarHookService(
      freightBolTrackingData,
      setProgress,
      setPickupInformation,
      setDeliveryInformation
    );
  }, [freightBolTrackingData]);

  return {
    progress,
    pickupInformation,
    deliveryInformation,
    status: freightBolTrackingData.bolStatus,
  };
};
