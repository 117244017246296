export const computeProgressBarStatus = (
  pickupDate: string | Date,
  deliveryDate: string | Date
): number => {
  const startDate = new Date(pickupDate);
  const endDate = new Date(deliveryDate);

  return Math.ceil(
    ((Date.now() - startDate.getTime()) /
      (endDate.getTime() - startDate.getTime())) *
      100
  );
};
