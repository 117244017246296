import { Anchor, NotificationProps } from "@deliverr/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MailToFreight } from "../../common/linkToWebsite";

export enum BolInputError {
  BOL_NOT_FOUND = "BOL_NOT_FOUND",
  BOL_EMPTY = "BOL_EMPTY",
}

export const BolInputErrorMessage: Record<
  BolInputError,
  NotificationProps["content"]
> = {
  [BolInputError.BOL_EMPTY]: {
    title: (
      <FormattedMessage
        id="input.error.title.bolEmpty"
        defaultMessage="No BOL entered"
      />
    ),
    description: (
      <FormattedMessage
        id="input.error.description.bolEmpty"
        defaultMessage="Please enter a BOL"
      />
    ),
  },
  [BolInputError.BOL_NOT_FOUND]: {
    title: (
      <FormattedMessage
        id="input.error.title.bolNotFound"
        defaultMessage="BOL not found"
      />
    ),
    description: (
      <FormattedMessage
        id="input.error.description.bolNotFound"
        defaultMessage="If the issue persists, reach out to {email} or your account representative."
        values={{
          email: <Anchor href={MailToFreight}>freight@deliverr.com</Anchor>,
        }}
      />
    ),
  },
};
