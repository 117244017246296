import React from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";
import { useProgressBar } from "../../hooks/useProgressBar";
import { ProgressBarEndpointDetails } from "./ProgressBarEndpointDetails";
import { Bar } from "./Bar";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
  `
);

const ProgressBarTextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing.S4};
    flex-wrap: wrap;

    ${theme.mediaQueries.MD}{
      flex-direction: row;
      justify-content: space-between;
    }
  `
);
export default function ProgressBar() {
  const { progress, pickupInformation, deliveryInformation, status } =
    useProgressBar();
  return (
    <Container>
      <Bar progress={progress} status={status} />
      <ProgressBarTextContainer>
        {pickupInformation && (
          <ProgressBarEndpointDetails {...pickupInformation} />
        )}
        {deliveryInformation && (
          <ProgressBarEndpointDetails {...deliveryInformation} />
        )}
      </ProgressBarTextContainer>
    </Container>
  );
}
