import React, { FC } from "react";
import { Text } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";
import { OrderInformationProps } from "./interface/OrderDetailsInterface";
import { orderDetailsHeadingRecord } from "../../constants/OrderDetailConstants";

export const OrderInformation: FC<OrderInformationProps> = ({
  title,
  value,
}) => (
  <>
    <Text bold={true}>
      <FormattedMessage {...orderDetailsHeadingRecord[title]} />
    </Text>
    <Text>{value}</Text>
  </>
);
