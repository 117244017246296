import {
  FreightBolStatus,
  FreightBolTracking,
} from "../../models/FreightBolTracking";
import { computeProgressBarStatus } from "./mapper/ProgressBarProgressMapper";
import {
  mapPickupStatusToProgressBar,
  mapDeliveryStatusToProgressBar,
  DeliveryInformation,
} from "./mapper/ProgressBarStatusMapper";

export const progressBarHookService = (
  freightBolTrackingData: FreightBolTracking,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  setPickupInformation: React.Dispatch<
    React.SetStateAction<DeliveryInformation | undefined>
  >,
  setDeliveryInformation: React.Dispatch<
    React.SetStateAction<DeliveryInformation | undefined>
  >
): void => {
  const {
    estimatedPickupTime,
    estimatedDeliveryTime,
    bolStatus,
    events,
    pickupDetails,
    deliveryDetails,
  } = freightBolTrackingData;

  const pickup = mapPickupStatusToProgressBar(
    bolStatus,
    pickupDetails.appointmentWindow,
    events,
    estimatedPickupTime
  );
  const delivery = mapDeliveryStatusToProgressBar(
    bolStatus,
    deliveryDetails.appointmentWindow,
    events,
    estimatedDeliveryTime
  );

  const progress = computeProgress(bolStatus, pickup.date, delivery.date);
  setProgress(progress);
  setPickupInformation(pickup);
  setDeliveryInformation(delivery);
};

const computeProgress = (
  bolStatus: FreightBolStatus,
  pickupDate: Date,
  deliveryDate: Date
): number => {
  const currentProgress = computeProgressBarStatus(pickupDate, deliveryDate);
  const isProgressCompleted =
    bolStatus !== FreightBolStatus.DELIVERED && currentProgress >= 95;

  if (
    bolStatus === FreightBolStatus.DELIVERED ||
    bolStatus === FreightBolStatus.CANCELLED
  ) {
    return 100;
  } else if (bolStatus === FreightBolStatus.CREATED) {
    return 0;
  } else if (isProgressCompleted) {
    return 95;
  } else {
    return currentProgress;
  }
};
