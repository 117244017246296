import { AppointmentWindow, DeliverrAddress } from "@deliverr/commons-objects";

export interface FreightStopDetails {
  appointmentWindow: AppointmentWindow;
  address: DeliverrAddress;
}

export interface FreightTrackingLocation {
  readonly name?: string;
  readonly street1?: string;
  readonly street2?: string;
  readonly city?: string;
  readonly zip?: string;
  readonly state?: string;
  readonly country?: string;
  readonly timezone?: string;
  readonly latitude?: number;
  readonly longitude?: number;
}

export enum FreightShipmentMode {
  LTL = "LTL",
  FTL = "FTL",
  DRAYAGE = "DRAYAGE", // tranport from an ocean port
  INTERMODAL = "INTERMODAL", // typically means transport over rail
  TRANSLOAD = "TRANSLOAD",
  VLTL = "VLTL",
}

export enum FreightTrackingEventType {
  CREATED = "CREATED",
  TENDERED = "TENDERED",
  ESTIMATED_PICKUP_DATE_CHANGED = "ESTIMATED_PICKUP_DATE_CHANGED",
  PICKED_UP = "PICKED_UP",
  LOCATION_UPDATED = "LOCATION_UPDATED",
  DELIVERED = "DELIVERED",
  ESTIMATED_DELIVERY_DATE_CHANGED = "ESTIMATED_DELIVERY_DATE_CHANGED",
  CANCELLED = "CANCELLED",
}

export interface FreightTrackingEvent {
  readonly occurredAt: string;
  readonly location?: FreightTrackingLocation;
  readonly eventType: FreightTrackingEventType;
  readonly bolStatus: FreightBolStatus;
}

export enum FreightBolStatus {
  CREATED = "CREATED",
  TENDERED = "TENDERED",
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}

export interface FreightBolTracking {
  readonly bolId: string;
  readonly bolStatus: FreightBolStatus;
  readonly carrierName?: string;
  readonly pickupDetails: FreightStopDetails;
  readonly deliveryDetails: FreightStopDetails;
  readonly estimatedPickupTime?: Date;
  readonly estimatedDeliveryTime?: Date;
  readonly totalPallets?: number;
  readonly mode?: FreightShipmentMode;
  readonly events: FreightTrackingEvent[];
  readonly updatedAt: Date;
}
