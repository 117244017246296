import { Notification, Text, ThemeProps, Title } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import vector from "../../assets/Vector.svg";
import { BolInputErrorMessage } from "./BolInputError";
import { BolInputProps } from "./BolInputProps";
import truck from "../../assets/truck.png";
import { BolInputButton, BolInputComponent, Img } from "./SharedComponents";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["00"]};
    display: flex; 
    flex-direction: column; 
    justify-items: center;  
    gap: ${theme.spacing.S6};
    background: url(${vector}), linear-gradient(180deg, rgba(4,22,39,1) 0%, rgba(92,9,121,1) 59%);
    padding-bottom: 50px;
  `
);

const MarginBolInputButton = styled(BolInputButton)<ThemeProps>(
  ({ theme }) => `
  margin-top: -${theme.spacing.S4};
  `
);

const ImgContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S4};
    margin-bottom: -140px; 
  `
);

export const MobileBolInput: FC<BolInputProps> = ({
  bol,
  onBolChange,
  onClick,
  onKeyDown,
  error,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Title textAlign={TextAlign.center}>
        <FormattedMessage
          id="input.title"
          defaultMessage="Where it {br} needs to be. {br} When it {br} needs to be."
          values={{ br: <br /> }}
        />
      </Title>
      <BolInputComponent
        placeholder={formatMessage({
          id: "input.placeholder",
          defaultMessage: "Enter BOL number",
        })}
        value={bol}
        onChange={onBolChange}
        onKeyDown={onKeyDown}
      />
      <MarginBolInputButton onClick={onClick}>
        <Text textAlign={TextAlign.center} bold size="bodyLarge">
          <FormattedMessage id="input.button" defaultMessage="Track Shipment" />
        </Text>
      </MarginBolInputButton>
      {error && (
        <Notification
          appearance="DANGER"
          content={{
            ...BolInputErrorMessage[error],
          }}
        />
      )}
      <ImgContainer>
        <Img src={truck} />
      </ImgContainer>
    </Container>
  );
};
