import { useAboveLarge } from "@deliverr/ui";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BolInputError } from "./BolInputError";

export const useBolInput = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const isAboveLarge = useAboveLarge();

  const [bol, setBolValue] = useState("");
  const [error, setError] = useState<BolInputError | undefined>(
    state.error ? BolInputError.BOL_NOT_FOUND : undefined
  );

  const onBolChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(undefined);
    }

    setBolValue(value);
  };

  const onKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === "Enter") {
      if (!bol) {
        setError(BolInputError.BOL_EMPTY);
        return;
      }

      navigate(`/bol/${bol}`);
    }
  };

  const onClick = () => {
    if (!bol) {
      setError(BolInputError.BOL_EMPTY);
      return;
    }

    navigate(`/bol/${bol}`);
  };

  return {
    bol,
    isAboveLarge,
    onBolChange,
    onClick,
    onKeyDown,
    error,
  };
};
