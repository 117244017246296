import { Grid, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DeliverrWebsite, linkToWebsite } from "../../common/linkToWebsite";
import { maxWidthCenterStyle } from "../../common/styleUtils";
import { company } from "./company";
import { FooterColumn } from "./FooterColumn";
import { popularArticles } from "./popularArticles";
import { Socials } from "./Socials";

const Container = styled.footer<ThemeProps>(
  ({ theme }) => `
    ${maxWidthCenterStyle}
    margin-top: ${theme.spacing.S6};
`
);

const FooterGrid = styled(Grid)`
  margin-bottom: 50px;
`;

const LegalDetailsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    align-self: start;
    color: ${theme.colors.NEUTRAL["100"]};
    padding: 30px 0px;
    font-size: ${theme.font.size.F2};

    a {
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        color: inherit;
        text-decoration: none; 
        &:not(:last-of-type):after {

          position: absolute;
          right: -2px;
          content: "|";
        }
    }
  `
);

const SocialDiv = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex; 
    margin-top: ${theme.spacing.S5};

    * {
      :not(:last-child) {
        margin-left: 0;
        margin-right: ${theme.spacing.S3};
      }
    }

    ${theme.mediaQueries.LG} {
      text-align: right;
      justify-self: end; 
      margin-top: 0; 
    }
  `
);

export const Footer = () => {
  return (
    <Container>
      <FooterGrid columns={{ BASE: "1fr", MD: "25% 25% auto" }}>
        <FooterColumn
          title={
            <FormattedMessage
              id="footer.company.title"
              defaultMessage="COMPANY"
            />
          }
          items={company}
        />
        <FooterColumn
          title={
            <FormattedMessage
              id="footer.popularArticles.title"
              defaultMessage="POPULAR ARTICLES"
            />
          }
          items={popularArticles}
        />
        <SocialDiv>
          <Socials />
        </SocialDiv>
      </FooterGrid>
      <LegalDetailsContainer>
        &copy; 2022 Deliverr&trade;, Inc.{" "}
        <a href={linkToWebsite(DeliverrWebsite, "/terms-of-service-2")}>
          <FormattedMessage
            id="footer.legal.terms"
            defaultMessage="Terms of Service"
          />
        </a>
        <a href={linkToWebsite(DeliverrWebsite, "/privacy")}>
          <FormattedMessage
            id="footer.legal.privacy"
            defaultMessage="Privacy"
          />
        </a>
      </LegalDetailsContainer>
    </Container>
  );
};
