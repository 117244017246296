import React, { FC } from "react";
import { Card, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import {
  NavDropdownItemLink,
  NavDropdownItemProps,
} from "./NavDropdownItemLink";
import { NAV_BLADE_COLOR } from "./NavBlade";

const NAV_DROPDOWN_CARD_WIDTH = 200;

const NavDropdownCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
      width: ${NAV_DROPDOWN_CARD_WIDTH}px; 
      border-radius: ${theme.border.radius.R3};
      display: flex; 
      flex-direction: column;
      color: ${theme.colors.NEUTRAL["500"]};
      padding: ${theme.spacing.S2};
      background-color: ${NAV_BLADE_COLOR};
      position: relative;

      ${theme.mediaQueries.LG}{
        background-color: ${theme.colors.NEUTRAL["00"]};
      }
    `
);

interface NavDropdownProps {
  items: NavDropdownItemProps[];
}

export const NavDropdown: FC<NavDropdownProps> = ({ items }) => {
  return (
    <NavDropdownCard>
      {items.map((itemProps) => (
        <NavDropdownItemLink {...itemProps} key={itemProps.href} />
      ))}
    </NavDropdownCard>
  );
};
