import { DefaultTheme } from "@deliverr/ui";

export const progressBarColorMapper = (
  theme: DefaultTheme,
  isFilled: boolean,
  isCancelled: boolean
): string => {
  if (isCancelled) {
    return theme.colors.RED[300];
  } else if (isFilled) {
    return theme.colors.GREEN[300];
  } else {
    return theme.colors.NEUTRAL[100];
  }
};
