import { Grid, Notification, Text, Title } from "@deliverr/ui";
import { TextAlign, ThemeProps } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BolInputButton, BolInputComponent, Img } from "./SharedComponents";
import { BolInputProps } from "./BolInputProps";
import { BolInputErrorMessage } from "./BolInputError";
import truck from "../../assets/truck.png";
import { maxWidthCenterStyle } from "../../common/styleUtils";
import { purpleBg } from "../../assets/WordpressAssets";
import vector from "../../assets/Vector.svg";

const Container = styled.div`
  ${maxWidthCenterStyle}
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
`;

const GridBodyContainer = styled(Grid)<ThemeProps>(
  ({ theme }) => `
    position: relative;
    z-index: ${theme.zindex.Z1} - 1;
    color: ${theme.colors.NEUTRAL["00"]};
  `
);

const GridContentContainer = styled(Grid)`
  grid-template-rows: 55% 20% 10%;
`;

const GridInputContainer = styled(Grid)`
  align-self: start;
`;

const InputTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
    font-size: 50px; 
    line-height: 70px;
    align-self: end;

    ${theme.mediaQueries.XL} {
      font-size: 55px;
    }
  `
);

const ErrorNotification = styled(Notification)<ThemeProps>(
  ({ theme }) => `
  margin-top: -${theme.spacing.S5};
  width: 60%;
  font-size: ${theme.font.size.F2};
  
  > span {
    width: 100%; 
  }
  `
);

const TruckImgContainer = styled.div`
  position: relative;
  width: 600px;
`;

const VectorImgContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute;
    left: -320px; 
    top: 150px;
    height: 460px; 
    width: 1675px; 
    z-index: ${theme.zindex.Z0}; 
  `
);

const BackgroundImgContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute; 
    width: 1200px; 
    height: 800px; 
    left: -400px; 
    top: -250px; 
    z-index: ${theme.zindex.Z0}; 
  `
);

const VectorImg = styled(Img)``;

export const DesktopBolInput: FC<BolInputProps> = ({
  bol,
  onBolChange,
  onClick,
  onKeyDown,
  error,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <VectorImgContainer>
        <VectorImg src={vector} />
      </VectorImgContainer>
      <BackgroundImgContainer>
        <Img src={purpleBg} />
      </BackgroundImgContainer>
      <GridBodyContainer columns={{ BASE: "60% 40%" }} gap="S8">
        <GridContentContainer alignItems="center" gap="S6">
          <InputTitle textAlign={TextAlign.start}>
            <FormattedMessage
              id="input.title"
              defaultMessage="Where it needs to be. {br} When it needs to be."
              values={{ br: <br /> }}
            />
          </InputTitle>
          <GridInputContainer columns={{ BASE: "60% 40%" }}>
            <BolInputComponent
              placeholder={formatMessage({
                id: "input.placeholder",
                defaultMessage: "Enter BOL number",
              })}
              value={bol}
              onChange={onBolChange}
              onKeyDown={onKeyDown}
            />
            <BolInputButton onClick={onClick}>
              <Text textAlign={TextAlign.center} bold size="bodyLarge">
                <FormattedMessage
                  id="input.button"
                  defaultMessage="Track Shipment"
                />
              </Text>
            </BolInputButton>
          </GridInputContainer>
          {error && (
            <ErrorNotification
              appearance="DANGER"
              content={{ ...BolInputErrorMessage[error] }}
            />
          )}
        </GridContentContainer>
        <TruckImgContainer>
          <Img src={truck} />
        </TruckImgContainer>
      </GridBodyContainer>
    </Container>
  );
};
