import React, { FC } from "react";
import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { OrderInformationProps } from "./interface/OrderDetailsInterface";
import { OrderInformation } from "./OrderInformation";

interface OrderInformationListProps {
  orderInformation: OrderInformationProps[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S4};
`
);

export const OrderInformationList: FC<OrderInformationListProps> = ({
  orderInformation,
}) => (
  <Container>
    {orderInformation.map(({ title, value }, idx) => (
      <ContentContainer key={idx}>
        <OrderInformation title={title} value={value} />
      </ContentContainer>
    ))}
  </Container>
);
