import React, { FC } from "react";
import { Title, Text, ThemeProps, IconV2, DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { OrderStatusProps } from "./interface/OrderDetailsInterface";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { FreightBolStatus } from "../../models/FreightBolTracking";
import { orderStatusRecord } from "../../constants/OrderDetailConstants";
import { useTheme } from "emotion-theming";
import { getLocalTimezone } from "../../utils/dateUtils";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  margin-bottom: ${theme.spacing.S6};
`
);

const StyledTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
  line-height: ${theme.font.lineHeight.LH7};
  font-size: ${theme.font.size.F6};

  ${theme.mediaQueries.MD}{
    font-size: ${theme.font.size.F7};
  }
`
);

const ContainerRow = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.S4};
  margin: ${theme.spacing.S2} 0;
`
);

const IconContainer = styled.span<{ backgroundColor: string }, DefaultTheme>(
  ({ theme, backgroundColor }) => `
  width: ${theme.spacing.S6};
  height: ${theme.spacing.S6};
  background-color: ${backgroundColor};
  border-radius: 50%;
  z-index: 1;
  > svg {
    margin: auto;
    display: block;
    height: 100%;
  }
`
);

export const OrderStatus: FC<OrderStatusProps> = ({
  bolId,
  status,
  lastUpdatedAt,
}) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <Container>
      <Text size="bodyLarge">{bolId}</Text>
      <ContainerRow>
        <StyledTitle displayAs="h1">
          <FormattedMessage {...orderStatusRecord[status]} />
        </StyledTitle>
        {status === FreightBolStatus.DELIVERED && (
          <IconContainer backgroundColor={theme.colors.GREEN[300]}>
            <IconV2 type="check" size="2x" color="white" />
          </IconContainer>
        )}
        {status === FreightBolStatus.CANCELLED && (
          <IconContainer backgroundColor={theme.colors.RED[300]}>
            <IconV2 type="close" size="2x" color="white" />
          </IconContainer>
        )}
      </ContainerRow>
      <Text appearance="INFO">
        <FormattedMessage
          id="orderStatus.event"
          defaultMessage="Last Event on"
        />{" "}
        <FormattedDate
          value={lastUpdatedAt}
          day="numeric"
          month="short"
          year="numeric"
        />
        {" - "}
        <FormattedTime value={lastUpdatedAt} />{" "}
        {getLocalTimezone(lastUpdatedAt.toString())}
      </Text>
    </Container>
  );
};
