import { AppointmentWindow } from "@deliverr/commons-objects";
import { MessageDescriptor } from "react-intl";
import {
  FreightBolStatus,
  FreightTrackingEvent,
  FreightTrackingEventType,
} from "../../../models/FreightBolTracking";
import {
  ProgressBarDeliveryLabelMap,
  ProgressBarPickupLabelMap,
} from "../ProgressBarConstants";

export interface DeliveryInformation {
  status: MessageDescriptor;
  date: Date;
  startTime: number;
  endTime?: number;
}

export const mapPickupStatusToProgressBar = (
  bolStatus: FreightBolStatus,
  appointmentWindow: AppointmentWindow,
  events: FreightTrackingEvent[],
  estimatedPickupTime: Date | undefined
): DeliveryInformation => {
  const pickupEventTime = events.find(
    (event) => event.eventType === FreightTrackingEventType.PICKED_UP
  )?.occurredAt;

  const currentPickupStatus = ProgressBarPickupLabelMap[bolStatus];

  const startTime = computeTime(
    appointmentWindow.startTime,
    appointmentWindow.date
  );
  const endTime = computeTime(
    appointmentWindow.endTime,
    appointmentWindow.date
  );
  switch (bolStatus) {
    case FreightBolStatus.CREATED:
    case FreightBolStatus.CANCELLED:
    case FreightBolStatus.TENDERED:
      return {
        status: currentPickupStatus,
        date: new Date(appointmentWindow.date),
        startTime,
        endTime,
      };
    case FreightBolStatus.IN_TRANSIT:
    case FreightBolStatus.DELIVERED: {
      const pickupTime = pickupEventTime
        ? new Date(pickupEventTime)
        : estimatedPickupTime;
      const pickupDate = pickupTime ?? appointmentWindow.date;

      return {
        status: currentPickupStatus,
        date: new Date(pickupDate),
        startTime: pickupTime?.getTime() ?? startTime,
        endTime: !pickupTime ? endTime : undefined,
      };
    }

    default:
      throw new Error("Invalid BOL status");
  }
};

export const mapDeliveryStatusToProgressBar = (
  bolStatus: FreightBolStatus,
  appointmentWindow: AppointmentWindow,
  events: FreightTrackingEvent[],
  estimatedDeliveryTime: Date | undefined
): DeliveryInformation => {
  const deliverEventTime = events.find(
    (event) => event.eventType === FreightTrackingEventType.DELIVERED
  )?.occurredAt;

  const startTime = computeTime(
    appointmentWindow.startTime,
    appointmentWindow.date
  );
  const endTime = computeTime(
    appointmentWindow.endTime,
    appointmentWindow.date
  );
  const currentDeliveryStatus = {
    id: ProgressBarDeliveryLabelMap[bolStatus].id!,
    defaultMessage: ProgressBarDeliveryLabelMap[bolStatus].defaultMessage,
  };
  switch (bolStatus) {
    case FreightBolStatus.CREATED:
    case FreightBolStatus.CANCELLED:
    case FreightBolStatus.TENDERED:
    case FreightBolStatus.IN_TRANSIT:
      return {
        status: currentDeliveryStatus,
        date: new Date(appointmentWindow.date),
        startTime,
        endTime,
      };
    case FreightBolStatus.DELIVERED: {
      const deliverTime = deliverEventTime
        ? new Date(deliverEventTime)
        : estimatedDeliveryTime;
      const deliverDate = deliverTime ?? appointmentWindow.date;

      return {
        status: currentDeliveryStatus,
        date: new Date(deliverDate),
        startTime: deliverTime?.getTime() ?? startTime,
        endTime: !deliverTime ? endTime : undefined,
      };
    }

    default:
      throw new Error("Invalid BOL status");
  }
};

/**
 * This formats the given time with EST timezone
 * This is a temporary solution, will be replaced with the timezone field passed from the API once it is available
 * @returns time in milliseconds
 */
const computeTime = (time: string, date: string) => {
  const timeWithESTLocation = new Date(date + " " + time).toLocaleString(
    "en-US",
    {
      timeZone: "America/New_York",
    }
  );
  return new Date(timeWithESTLocation).getTime();
};
